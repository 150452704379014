import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Image } from "../components"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter: blog, html } = markdownRemark
  return (
    <Layout>
      <Seo
        title={blog.title}
        type="article"
        image={blog.thumbnail}
        description={blog.description}
      />

      <div className="container">
        <div className="blog-full-post">
          <div className="blog-full-header">
            <h1 className="blog-full-title">{blog.title}</h1>
            <h3 className="blog-full-date">{blog.date}</h3>
            <div className="blog-full-thumbnail-container">
              <Image src={blog.thumbnail} alt={blog.thumbnail_text} />
              <div className="blog-full-thumbnail-text">
                {blog.thumbnail_text}
              </div>
            </div>
          </div>
          <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>

        <h1 className="blog-full-afterword">Thanks for reading</h1>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($title: String!) {
    markdownRemark(frontmatter: { title: { eq: $title } }) {
      html
      frontmatter {
        date(formatString: "D MMMM YYYY")
        title
        thumbnail
        thumbnail_text
        description
      }
    }
  }
`
